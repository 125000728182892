import MockFactory from "./MockFactory";

/**
 * Get drive preregistration data
 * @param {string} id
 * @return {Promise}
 */
export const getPreRegData = (id) => MockFactory.get(`api/v1?link=${id}`);

/**
 * Save drive preregistration data
 * @param {object} data
 * @param {string} id
 * @return {Promise}
 */
export const savePreRegData = (data, id) => {
    return MockFactory.put(`api/v1?link=${id}`, data);
};

/** Fetching page masterdata
 * @return {Promise}
 */
export const getPageMetaData = () => MockFactory.get(`meta/configuration.json`);
