import React from "react";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import { Grid, TextField } from "@mui/material";
import { formInputCommonStyle } from "../../../../flow-core-components-shared/src/components/FormLayout/FormLayout";
import Autocomplete from "../../../../flow-core-components-shared/src/components/Autocomplete/Autocomplete";
import translate from "flow-core-library-translator";

import t from "../../translations.json";

/**
 * Vehicle section for DrivePreregistration form
 * @param {object} control React hook form control
 * @param {Array.object} countries Country dropdown data for the nationality field
 * @return {JSX.Element}
 */
const VehicleSection = ({ control, countries }) => {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
                <Controller
                    name="truckLicensePlate"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                        <TextField
                            id="inTruckLicensePlate"
                            required
                            error={!!error}
                            helperText={!!error && error.message}
                            fullWidth
                            label={translate(t, "fields.licensePlate")}
                            sx={formInputCommonStyle}
                            inputProps={{ maxLength: 20 }}
                            {...field}
                        />
                    )}
                />
            </Grid>
            <Grid item xs={12} sm={4}>
                <Controller
                    name="truckNationality"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                        <Autocomplete
                            id="inTruckNationality"
                            stringMode
                            error={!!error}
                            helperText={!!error && error.message}
                            label={translate(t, "fields.truckNationality")}
                            options={countries}
                            optionLabelPropName="name"
                            optionValuePropName="code"
                            sx={formInputCommonStyle}
                            inputProps={{ required: true }}
                            {...field}
                        />
                    )}
                />
            </Grid>
            <Grid item xs={12} sm={4}>
                <Controller
                    name="totalPermissibleWeightKg"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                        <TextField
                            id="inTotalPermissibleWeightKg"
                            error={!!error}
                            helperText={!!error && error.message}
                            fullWidth
                            label={translate(t, "fields.totalWeight")}
                            sx={formInputCommonStyle}
                            inputProps={{ maxLength: 5 }}
                            {...field}
                        />
                    )}
                />
            </Grid>
        </Grid>
    );
};

VehicleSection.propTypes = {
    control: PropTypes.object,
    countries: PropTypes.arrayOf(
        PropTypes.shape({
            code: PropTypes.string,
            name: PropTypes.string,
        })
    ),
};

export default VehicleSection;
