import React, { useState, useMemo } from "react";
import { PropTypes } from "prop-types";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import translate from "flow-core-library-translator";

import t from "../translations";

export const formInputCommonStyle = {
    "& .MuiInputBase-root": {
        backgroundColor: "#FFFFFF",
    },
};

/**
 * Reusable generic Form Layout component in order to keep style consistent
 * It displays the form in sections, display/hide advanced search sections
 * Displays buttons at the bottom of the form
 * @param {Array.<object>} sections Sections of the form, which should be displayed with label and divider on the bottom
 * @param {Array.<object>} buttons Buttons of the form
 * @param {function} onSubmit Call this function onSubmit event
 * @param {JSX.Element} bottomNote Bottom note component in the same line as buttons
 * @return {JSX.Element}
 */
const FormLayout = ({ sections, buttons, onSubmit, bottomNote }) => {
    // Advanced form sections is displayed or not
    const [advancedSearch, setAdvancedSearch] = useState(false);

    // Get the sections which should be dispayed initialy (not additional)
    const sectionsToDisplay = useMemo(
        () =>
            sections.filter(({ additional }) => !additional || advancedSearch),
        [advancedSearch, sections]
    );

    // Check sections if there is additional element
    const isThereAdditionalSection = useMemo(
        () => sections.findIndex((section) => section.additional) !== -1,
        [sections]
    );

    return (
        <Box
            sx={{
                backgroundColor: "#FAFAFA",
                borderRadius: "12px",
                padding: 3,
            }}
        >
            <form autoComplete="off" onSubmit={onSubmit}>
                <Grid container direction="row" spacing={3}>
                    {sectionsToDisplay.map(({ label, content }, key) => (
                        <React.Fragment key={`searchFormSection_${key}`}>
                            <Grid item xs={12} lg={3}>
                                <Typography
                                    sx={{
                                        fontWeight: 500,
                                        fontSize: "20px",
                                        lineHeight: "160%",
                                        letterSpacing: "0.15px",
                                    }}
                                >
                                    {label}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} lg={9} xl={6}>
                                {content}
                            </Grid>

                            {key + 1 < sectionsToDisplay.length ? (
                                <Grid item xs={12}>
                                    <Divider />
                                </Grid>
                            ) : (
                                <Grid
                                    item
                                    xs={12}
                                    sx={{
                                        p: "0 !important",
                                    }}
                                />
                            )}
                        </React.Fragment>
                    ))}
                    {/* Placeholder before the foot note, positions horizotally */}
                    <Grid item xs={12} lg={3} sx={{ p: "0 !important" }} />
                    <Grid item xs={12} lg={9} xl={6}>
                        <Grid
                            container
                            spacing={2}
                            justifyContent="space-between"
                        >
                            <Grid item xs={12} sm>
                                {!!bottomNote && bottomNote}
                            </Grid>
                            <Grid item xs={12} sm>
                                <Stack
                                    direction="row"
                                    spacing={2}
                                    justifyContent="end"
                                    alignItems="flex-end"
                                >
                                    {/* Display "more search options" button only when there are additional sections */}
                                    {isThereAdditionalSection && (
                                        <Link
                                            sx={{
                                                fontFamily: "Roboto",
                                                textTransform: "uppercase",
                                                fontWeight: 500,
                                                cursor: "pointer",
                                            }}
                                            onClick={() =>
                                                setAdvancedSearch(
                                                    !advancedSearch
                                                )
                                            }
                                        >
                                            {translate(
                                                t,
                                                advancedSearch
                                                    ? "FormLayout.lessSearchParams"
                                                    : "FormLayout.moreSearchParams"
                                            )}
                                        </Link>
                                    )}
                                    {buttons.map(
                                        (
                                            {
                                                label,
                                                disabled,
                                                onClick,
                                                sx,
                                                ...rest
                                            },
                                            key
                                        ) => (
                                            <Button
                                                key={`formButton_${key}`}
                                                disabled={disabled}
                                                onClick={onClick}
                                                variant="contained"
                                                sx={{
                                                    textDecoration: "none",
                                                    fontWeight: 500,
                                                    fontSize: "15px",
                                                    lineHeight: "26px",
                                                    letterSpacing: "0.46px",
                                                    ...sx,
                                                }}
                                                {...rest}
                                            >
                                                {label}
                                            </Button>
                                        )
                                    )}
                                </Stack>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </form>
        </Box>
    );
};

FormLayout.propTypes = {
    onSubmit: PropTypes.func,
    bottomNote: PropTypes.element,
    sections: PropTypes.arrayOf(
        PropTypes.shape({
            additional: PropTypes.bool,
            label: PropTypes.string,
            content: PropTypes.element,
        })
    ),
    buttons: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            disabled: PropTypes.bool,
            onClick: PropTypes.func,
        })
    ),
};

export default FormLayout;
