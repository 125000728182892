import React, { useState, cloneElement } from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import Switch from "@mui/material/Switch";
import {
    usePickerLayout,
    pickersLayoutClasses,
    PickersLayoutRoot,
    PickersLayoutContentWrapper,
} from "@mui/x-date-pickers/PickersLayout";
import translate from "flow-core-library-translator";

import TimePicker from "./TimePicker";
import t from "../translations.json";
import useMobileView from "../../hooks/useMobileView";

/**
 * Custom layout component for DateTimePicker component
 * @param {object} props
 * @return {*}
 */
const Layout = (props) => {
    const { value, onChange } = props;
    const { content, actionBar } = usePickerLayout(props);
    const [timeAdjust, setTimeAdjust] = useState(false);
    const isMobileView = useMobileView();

    /* The original onChange event close the picker when a day selected
    therefore we need to override it.*/
    const calendar = cloneElement(content, { onChange });

    return (
        <PickersLayoutRoot ownerState={props} sx={{}}>
            <PickersLayoutContentWrapper
                className={pickersLayoutClasses.contentWrapper}
            >
                <Grid container direction="row">
                    <Grid
                        item
                        xs
                        container
                        direction="column"
                        alignItems="flex-end"
                        spacing={1}
                    >
                        <Grid item>{calendar}</Grid>
                        {/* In mobile view we need to display the TimePicker in other place */}
                        {timeAdjust && isMobileView && (
                            <Grid
                                item
                                xs
                                sx={{
                                    width: "100%",
                                }}
                            >
                                <TimePicker value={value} onChange={onChange} />
                            </Grid>
                        )}
                        <Grid item>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={timeAdjust}
                                        onChange={(event) =>
                                            setTimeAdjust(event.target.checked)
                                        }
                                    />
                                }
                                label={translate(
                                    t,
                                    "dateTimePicker.labels.customTime"
                                )}
                            />
                        </Grid>
                        <Grid item>
                            <Button onClick={actionBar.props.onCancel}>
                                {translate(t, "dateTimePicker.buttons.cancel")}
                            </Button>
                            <Button onClick={actionBar.props.onAccept}>
                                {translate(t, "dateTimePicker.buttons.ok")}
                            </Button>
                        </Grid>
                    </Grid>
                    {/* In mobile view we need to display TimePicker here */}
                    {timeAdjust && !isMobileView && (
                        <Grid item xs>
                            <TimePicker value={value} onChange={onChange} />
                        </Grid>
                    )}
                </Grid>
            </PickersLayoutContentWrapper>
        </PickersLayoutRoot>
    );
};

Layout.propTypes = {
    value: PropTypes.object,
    onChange: PropTypes.func,
};

export default Layout;
