import React from "react";
import PropTypes from "prop-types";
import { Box, Divider, Stack } from "@mui/material";

import PageHeader from "./PageHeader";

const containerStyle = {
    default: {
        mt: 2.5,
        mb: 2.5,
        ml: 7.5,
        mr: 7.5,
    },
    flow5: {
        p: {
            xs: 2,
            md: 3,
            lg: 6,
        },
    },
};

/**
 * Sets up common stlye configurtion and header component for pages, implementing a new MUI layout.
 * Handle variants: default (legacy style) and flow5 (new style for search forms)
 * @param {object} children
 * @param {('default' | 'flow5')} variant
 * @param {object} headerProps
 * @param {JSX.Element} HeaderComponent Custom component for displaying and wrapping header
 * @param {boolean} hideTopDivider Hide the top divider
 * @param {JSX.Element} footer Optional footer component to the bottom of the page
 * @return {JSX.Element}
 * @constructor
 */
const MuiPage = ({
    children,
    variant = "default",
    headerProps,
    HeaderComponent,
    hideTopDivider,
    footer,
}) => {
    const Header = headerProps ? <PageHeader {...headerProps} /> : null;

    return (
        <React.Fragment>
            {variant === "flow5" && !hideTopDivider && <Divider />}
            <Box
                sx={{
                    ...(footer ? { display: "flex", minHeight: "100vh" } : {}),
                    ...(containerStyle[variant] || containerStyle["default"]),
                }}
            >
                <Stack
                    direction="column"
                    alignItems="stretch"
                    spacing={3}
                    sx={footer && { flex: 1 }}
                >
                    {/* HEADER */}
                    {HeaderComponent ? (
                        <HeaderComponent>{Header}</HeaderComponent>
                    ) : (
                        Header
                    )}

                    {/* BODY */}
                    <Box sx={footer && { flex: 1 }}>{children}</Box>

                    {/* FOOTER */}
                    {!!footer && footer}
                </Stack>
            </Box>
        </React.Fragment>
    );
};

MuiPage.propTypes = {
    children: PropTypes.node,
    variant: PropTypes.oneOf(["default", "flow5"]),
    headerProps: PropTypes.shape({
        title: PropTypes.string,
        toolbar: PropTypes.element,
    }),
    HeaderComponent: PropTypes.func,
    hideTopDivider: PropTypes.bool,
    footer: PropTypes.element,
};

export default MuiPage;
