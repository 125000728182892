import React from "react";
import PropTypes from "prop-types";
import { Link, Stack, Typography } from "@mui/material";
import translate from "flow-core-library-translator";

import t from "../translations.json";

const linkProps = {
    target: "_blank",
    rel: "noreferrer",
    variant: "body1",
    underline: "none",
    sx: {
        fontSize: "14px",
        fontWeight: 400,
        color: ({ palette }) => palette.text.primary,
    },
};

/**
 * Custom footer component for drive pre registration page
 * @param {string} legalNoticeUrl
 * @param {string} dataProtectionGuidelineUrl
 * @param {string} productRevision
 * @return {JSX.Element}
 */
const Footer = ({
    legalNoticeUrl,
    dataprotectionGuidelinesUrl,
    productRevision,
}) => (
    <Stack direction="row" spacing={2} alignItems="center">
        <Link {...linkProps} href={legalNoticeUrl}>
            {translate(t, "footer.legalNotice")}
        </Link>
        <Link {...linkProps} href={dataprotectionGuidelinesUrl}>
            {translate(t, "footer.dataProtectionGuideline")}
        </Link>
        <Typography
            sx={{
                fontSize: "12px",
                fontWeight: 400,
                color: ({ palette }) => palette.text.secondary,
            }}
        >
            {productRevision || ""}
        </Typography>
    </Stack>
);

export const FooterProps = {
    dataprotectionGuidelinesUrl: PropTypes.string,
    legalNoticeUrl: PropTypes.string,
    productRevision: PropTypes.string,
};

Footer.propTypes = FooterProps;

export default Footer;
