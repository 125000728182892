import React from "react";
import PropTypes from "prop-types";
import { Typography, TableCell, Stack } from "@mui/material";
import translate from "flow-core-library-translator";
import InfoIcon from "@mui/icons-material/InfoOutlined";

import t from "../../translations.json";

/**
 * Table row component to display info message instead of value
 * @param {string} labelPath Key of the translation element which should be displayed
 * @return {JSX.Element}
 */
const InfoRow = ({ labelPath }) => (
    <TableCell colSpan={2}>
        <Stack direction="row" alignItems="center" spacing={1}>
            <InfoIcon
                sx={{
                    color: ({ palette }) => palette.text.secondary,
                    width: "13px",
                    height: "13px",
                }}
            />
            <Typography sx={{ fontSize: "12px", lineHeight: "22px" }}>
                {translate(t, labelPath)}
            </Typography>
        </Stack>
    </TableCell>
);

InfoRow.propTypes = {
    labelPath: PropTypes.string,
};

export default InfoRow;
