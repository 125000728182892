import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField"
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

let timerInterval;
let intervalCounter = 0;

/**
 * Unit part of the TimePicker, auto step value in case of long press on step buttons
 * @param {number} stepSpeed Speed of the stepping
 * @param {number} numberOfSlowSteps Number of the slow steps before speed up the stepping
 * @param {number} minValue Minimum value, below of this value the stepping stops
 * @param {number} maxValue Maximum value, over of this value the stepping stops
 * @param {number} value Current value
 * @param {function} onChange Function called in case of value has been chnaged
 * @param {boolean} disabled When it is true, the buttons and input filed are disabled
 * @return {JSX.Element}
 */
const TimePickerUnit = ({
    stepSpeed,
    numberOfSlowSteps,
    minValue,
    maxValue,
    value,
    onChange,
    disabled,
}) => {
    // Show value from state. Emit the onChange event, only when the step button has been released
    const [displayValue, setDisplayValue] = useState(value);

    useEffect(() => () => clearInterval(timerInterval), []);
    useEffect(() => setDisplayValue(parseInt(value)), [value]);

    /**
     * Handle mouse click or touch start
     * @param {number} value
     * @return {void}
     * */
    const handlePointerDown = (value) => {
        /**
         * Handle change displayValue state
         */
        const updateValue = () => {
            setDisplayValue((prevValue) => {
                // Ensure that, the new value is between the min and max value
                const newValue = prevValue + value;

                if (newValue >= minValue && newValue <= maxValue) {
                    return newValue;
                }

                return prevValue;
            });
        };

        // Handle simple click, first change before interval
        updateValue();
        intervalCounter++;

        // Handle button long press and slow down at begining
        timerInterval = setInterval(() => {
            if (intervalCounter < Math.pow(numberOfSlowSteps, 2)) {
                if (!(intervalCounter % numberOfSlowSteps)) {
                    updateValue();
                }
            } else {
                updateValue();
            }
            intervalCounter++;
        }, stepSpeed);
    };

    /**
     * Handle mouse or touch release, emit onChange function
     */
    const handlePointerUp = () => {
        clearInterval(timerInterval);
        intervalCounter = 0;
        onChange(displayValue);
    };

    /**
     * Handle manual value change
     * @param {*} event
     */
    const handleInputChange = (event) => {
        if (event.target.value <= maxValue && event.target.value >= minValue) {
            onChange(parseInt(event.target.value));
        }
    };

    return (
        <Stack direction="column" alignItems="center">
            <IconButton
                disabled={disabled}
                onPointerDown={() => handlePointerDown(1)}
                onPointerUp={() => handlePointerUp()}
            >
                <KeyboardArrowUpIcon />
            </IconButton>
            <TextField
                disabled={disabled}
                value={displayValue < 10 ? "0" + displayValue : displayValue}
                size="small"
                sx={{ width: "35px" }}
                inputProps={{
                    sx: {
                        padding: "8px",
                    },
                }}
                onChange={handleInputChange}
            />
            <IconButton
                disabled={disabled}
                onPointerDown={() => handlePointerDown(-1)}
                onPointerUp={() => handlePointerUp()}
            >
                <KeyboardArrowDownIcon />
            </IconButton>
        </Stack>
    );
};

TimePickerUnit.propTypes = {
    disabled: PropTypes.bool,
    maxValue: PropTypes.number,
    minValue: PropTypes.number,
    numberOfSlowSteps: PropTypes.number,
    onChange: PropTypes.func,
    stepSpeed: PropTypes.number,
    value: PropTypes.number,
};

export default TimePickerUnit;
