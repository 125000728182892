import React from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

/**
 * Header element for MuiPage component
 * @param {string} title Title of the page
 * @param {JSX.Element} toolbar Display this element if it is defined, right side of the title line
 * @return {JSX.Element}
 */
const PageHeader = ({ title, toolbar }) => (
    <Grid
        container
        direction="row"
        justifyContent="space-between"
        spacing={2}
        sx={{
            m: 0,
            maxWidth: "100%",
            "& .MuiGrid-root": { pl: 0 },
        }}
    >
        {title && (
            <Grid item>
                <Typography
                    id="pageTitle"
                    variant="h4"
                    sx={{
                        fontWeight: 400,
                        fontSize: {
                            xs: "24px",
                            md: "34px",
                        },
                    }}
                >
                    {title}
                </Typography>
            </Grid>
        )}
        {toolbar && <Grid item>{toolbar}</Grid>}
    </Grid>
);

PageHeader.propTypes = {
    title: PropTypes.string,
    toolbar: PropTypes.element,
};

export default PageHeader;
