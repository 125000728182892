import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import FormLayout from "../../../flow-core-components-shared/src/components/FormLayout/FormLayout";
import Dialog from "../../../flow-core-components-shared/src/components/Dialog/Dialog";
import { Typography, Stack } from "@mui/material";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import translate from "flow-core-library-translator";
import moment from "moment";
import "moment/locale/de";

import t from "../translations.json";
import { getFormDefaultValues, formValidationSchema } from "../utils/form";
import FreightForwarderSection from "./FormSections/FreightForwarderSection";
import DriverSection from "./FormSections/DriverSection";
import VehicleSection from "./FormSections/VehicleSection";
import DateAndTimeSection from "./FormSections/DateAndTimeSection";
import DriveDetails, { DriveDetailsProps } from "./DriveDetails/DriveDetails";
import { savePreRegData } from "../api/Api";

/* TBD - Translate and move helperText mesages to a shared place */
/* TBD - Add kg to totalWeight label english translation */

/**
 * Drive preregistration form component
 * @param {object} driveDetails Object for display drive detail above the form
 * @param {function} onSuccessSubmit Call this function when the form data successfuly saved
 * @param {string} urlLinkId Link id from url
 * @return {JSX.Element}
 */
const PreregistrationForm = ({ driveDetails, onSuccesSubmit, urlLinkId }) => {
    const [errorDialog, setErrorDialog] = useState(false);

    const { control, handleSubmit, formState, trigger, reset } =
        useForm({
            defaultValues: getFormDefaultValues(driveDetails.preregistration),
            mode: "onTouched",
            resolver: yupResolver(formValidationSchema),
        });

    useEffect(() => {
        reset(getFormDefaultValues(driveDetails.preregistration));
    }, [driveDetails]);

    /** Handle form submit event
     * @param {object} data
     * @return {void}
     */
    const onSubmit = (data) => {
        savePreRegData(
            {
                ...data,
                // prettier-ignore
                ...(data.driverDateOfBirth
                    ? {
                        driverDateOfBirth: moment(
                            data.driverDateOfBirth
                        ).format(),
                    }
                    : {}),
                // prettier-ignore
                ...(data.codriverDateOfBirth
                    ? {
                        codriverDateOfBirth: moment(
                            data.codriverDateOfBirth
                        ).format(),
                    }
                    : {}),
                // prettier-ignore
                ...(data.estimatedTimeOfArrival
                    ? {
                        estimatedTimeOfArrival: moment(
                            data.estimatedTimeOfArrival
                        ).format(),
                    }
                    : {}),
            },
            urlLinkId
        )
            .then(() => {
                reset();
                onSuccesSubmit();
            })
            .catch(() => setErrorDialog(true));
    };

    return (
        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="de">
            <Dialog
                open={errorDialog}
                onClose={() => setErrorDialog(false)}
                variant="warning"
                title={translate(t, "saveErrorDialog.title")}
                text={translate(t, "saveErrorDialog.text")}
                maxWidth="sm"
                fullWidth
            />
            <Stack spacing={3}>
                <DriveDetails driveDetails={driveDetails.drive} />
                <FormLayout
                    onSubmit={handleSubmit(onSubmit)}
                    bottomNote={
                        <Typography sx={{ fontSize: "12px", fontWeight: 400 }}>
                            {translate(t, "labels.mandatory")}
                        </Typography>
                    }
                    sections={[
                        {
                            label: translate(t, "sections.freightForwarder"),
                            content: (
                                <FreightForwarderSection control={control} />
                            ),
                        },
                        {
                            label: translate(t, "sections.driver"),
                            content: (
                                <DriverSection
                                    control={control}
                                    trigger={trigger}
                                    countries={driveDetails.countries}
                                />
                            ),
                        },
                        {
                            label: translate(t, "sections.codriver"),
                            content: (
                                <DriverSection
                                    isCoDriver
                                    control={control}
                                    countries={driveDetails.countries}
                                />
                            ),
                        },
                        {
                            label: translate(t, "sections.vehicle"),
                            content: (
                                <VehicleSection
                                    control={control}
                                    countries={driveDetails.countries}
                                />
                            ),
                        },
                        {
                            label: translate(t, "sections.estimatedArrival"),
                            content: (
                                <DateAndTimeSection
                                    control={control}
                                    trigger={trigger}
                                />
                            ),
                        },
                    ]}
                    buttons={[
                        {
                            id: "btnSave",
                            label: translate(t, "buttons.save"),
                            disabled: !formState.isValid,
                            type: "submit",
                            sx: {
                                width: {
                                    xs: "100%",
                                    sm: "auto",
                                },
                            },
                        },
                    ]}
                />
            </Stack>
        </LocalizationProvider>
    );
};

PreregistrationForm.propTypes = {
    driveDetails: PropTypes.shape(DriveDetailsProps),
    onSuccesSubmit: PropTypes.func,
    urlLinkId: PropTypes.string,
};

export default PreregistrationForm;
