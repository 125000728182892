import React from "react";
import { Box, Stack } from "@mui/material";
import FlowPreristrationLogo from "../../assets/flow-preregistration.png";
import StarTracLogo from "../../assets/star-trac.png";

/**
 * Custom header component for drive preregistration form
 * @return {JSX.Element}
 */
const CustomHeader = ({ children }) => (
    <Stack spacing={4}>
        <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
        >
            {/* CUSTOMER LOGO */}
            <Box
                component="img"
                sx={{
                    width: { xs: "64px", sm: "101px", md: "138px" },
                }}
                src={
                    process.env.NODE_ENV === "development"
                        ? "public/customer_logo.png"
                        : "meta/customer_logo.png"
                }
            />

            <Stack direction="row" alignItems="center">
                {/* FLOW PREREGISTRATION LOGO */}
                <Box
                    component="img"
                    sx={{
                        marginRight: { xs: 2, md: 4 },
                        width: { xs: "119px", sm: "188px", md: "258px" },
                        height: { xs: "12px", sm: "19px", md: "27px" },
                    }}
                    src={FlowPreristrationLogo}
                ></Box>
                {/* STAR-TRAC LOGO */}
                <Box
                    component="img"
                    sx={{
                        width: { xs: "70px", sm: "111px", md: "153px" },
                        height: { xs: "22px", sm: "35px", md: "48px" },
                    }}
                    src={StarTracLogo}
                ></Box>
            </Stack>
        </Stack>
        {children}
    </Stack>
);

export default CustomHeader;
