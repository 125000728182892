import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";

/**
 * Custom hook to return screen is in mobile view
 * @return {boolean}
 */
const useMobileView = () => {
    const theme = useTheme();
    return useMediaQuery(theme.breakpoints.down("sm"));
};

export default useMobileView;
