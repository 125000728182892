import React from "react";
import PropTypes from "prop-types";
import { TableCell } from "@mui/material";
import translate from "flow-core-library-translator";

import t from "../../translations.json";

/**
 * Table row component to display drive details
 * @param {string} labelPath Key of the translation element which should be displayed
 * @param {string} value Value to display
 * @param {string} valueCellId Id of the value cell
 * @return {JSX.Element}
 */
const ValueRow = ({ labelPath, value, valueCellId }) => (
    <>
        <TableCell
            sx={{
                fontWeight: 500,
                fontSize: "16px",
                width: "150px",
            }}
        >
            {translate(t, labelPath)}:
        </TableCell>
        <TableCell
            id={valueCellId}
            sx={{
                fontWeight: 400,
                fontSize: "16px",
            }}
        >
            {value || ""}
        </TableCell>
    </>
);

ValueRow.propTypes = {
    labelPath: PropTypes.string,
    value: PropTypes.string,
    valueCellId: PropTypes.string,
};

export default ValueRow;
