import React from "react";
import PropTypes from "prop-types";
import { Table, TableBody, TableRow, Stack, Divider, Box } from "@mui/material";

import ValueRow from "./ValueRow";
import InfoRow from "./InfoRow";

/** Details table component
 * @param {Array.object} rows
 * @param {boolean} noDivider
 * @return {JSX.Element}
 */
const DetailsTable = ({ rows, noDivider }) => (
    <Stack
        direction="row"
        sx={{ height: "100%", maxWidth: "600px" }}
        alignItems={"start"}
    >
        <Table
            sx={{
                "& .MuiTableCell-root": {
                    pl: 0,
                    py: 0.25,
                    border: "none",
                    verticalAlign: "top",
                },
            }}
        >
            <TableBody>
                {rows.map((row, index) => (
                    <TableRow key={`driveDetailsTableRow_${index}`}>
                        {row.value ? (
                            <ValueRow {...row} />
                        ) : (
                            <InfoRow {...row} />
                        )}
                    </TableRow>
                ))}
            </TableBody>
        </Table>

        <Box
            sx={{
                width: "16px",
                height: "100%",
                display: "flex",
            }}
        >
            {!noDivider && <Divider orientation="vertical" flexItem />}
        </Box>
    </Stack>
);

DetailsTable.propTypes = {
    rows: PropTypes.arrayOf(
        PropTypes.shape({
            labelPath: PropTypes.string,
            value: PropTypes.string,
        })
    ),
    noDivider: PropTypes.bool,
};

export default DetailsTable;
