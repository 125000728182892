import React from "react";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import translate from "flow-core-library-translator";
import DateTimePicker from "../../../../flow-core-components-shared/src/components/DateTimePicker/DateTimePicker";
import { formInputCommonStyle } from "../../../../flow-core-components-shared/src/components/FormLayout/FormLayout";

import t from "../../translations.json";

/**
 * DateAndTime section for DrivePreregistration form
 * @param {object} control React hook form control
 * @param {function} trigger Function for init validation manually on a field
 * @return {JSX.Element}
 */
const DateAndTimeSection = ({ control, trigger }) => {
    return (
        <Controller
            name="estimatedTimeOfArrival"
            control={control}
            render={({ field, fieldState: { error } }) => (
                <DateTimePicker
                    label={translate(t, "fields.dateAndTime")}
                    inputProps={{
                        id: "inEstimatedTimeOfArrival",
                        sx: formInputCommonStyle,
                        required: true,
                        error: !!error,
                        helperText: !!error && error.message,
                        ...field
                    }}
                    onChange={field.onChange}
                    onClose={(value) => {
                        // Validate when a dialog was open but no value was selected
                        if (!value && trigger) {
                            trigger("estimatedTimeOfArrival");
                        }
                    }}
                />
            )}
        />
    );
};

DateAndTimeSection.propTypes = {
    control: PropTypes.object,
    trigger: PropTypes.func,
};

export default DateAndTimeSection;
