import React, { useEffect, useState, useMemo } from "react";
import PropTypes from "prop-types";
import { Grid, Typography } from "@mui/material";
import translate from "flow-core-library-translator";

import t from "../../translations.json";
import DetailsTable from "./DetailsTable";

/**
 * Drive detail tables section above the form
 * @return {JSX.Element}
 */
const DriveDetails = ({ driveDetails }) => {
    // Contains the table items containe width for calculating line breaks
    const [containerWidth, setContainerWidth] = useState();

    // Contains the item widths for calculation line breaks
    const [itemWidths, setItemWidths] = useState();

    useEffect(() => {
        // Collect items to be observed (table items and the container)
        const itemsToBeObserved = [
            ...(document.querySelectorAll(".detailsTableItem") || []),
            document.getElementById("detailsTableContainer"),
        ];

        const observer = new ResizeObserver((entries) => {
            for (let entry of entries) {
                // Identify container width change and store in state
                if (entry.target.id === "detailsTableContainer") {
                    setContainerWidth(entry.contentBoxSize[0].inlineSize);
                }

                // Identify items width change and store in state
                if (entry.target.className.includes("detailsTableItem")) {
                    const itemPosition =
                        entry.target.attributes.itemposition.value;
                    const itemWidth = entry.borderBoxSize[0].inlineSize;

                    setItemWidths((prevValues) => ({
                        ...prevValues,
                        [`item_${itemPosition}`]: itemWidth,
                    }));
                }
            }
        });

        itemsToBeObserved.forEach((item) => observer.observe(item));

        // Cleanup
        return () => observer.disconnect();
    }, []);

    /** Obect for describing table items, if it should have divider or not */
    const itemDividers = useMemo(() => {
        if ((containerWidth, itemWidths)) {
            const itemKeys = Object.keys(itemWidths);

            // Value for keep the available space in current line
            let lineWidth = containerWidth;

            return itemKeys.reduce((acc, itemName, index) => {
                // Current item width
                const itemWidth = itemWidths[itemName];

                // Get the next item width for calculating if there will be line break
                const nextItemWidth = itemWidths[itemKeys[index + 1]];

                // Calculating if there will be line break
                const lineBreak =
                    lineWidth - itemWidth < nextItemWidth || !nextItemWidth;

                // Update available space for the next item check
                // If there is no enough spce for the next element, reset it with an entire row width value
                lineWidth = lineBreak ? containerWidth : lineWidth - itemWidth;

                return { ...acc, [itemName]: !lineBreak };
            }, {});
        }
    }, [containerWidth, itemWidths]);

    return (
        <Grid container spacing={0.25} id="detailsTableContainer">
            <Grid item xs={12} sx={{ mb: 3 }}>
                <Typography
                    id="driveDetailsTitle"
                    sx={{
                        fontWeight: 400,
                        color: ({ palette }) => palette.text.primary,
                        fontSize: { xs: "20px", sm: "24px" },
                    }}
                >
                    {`${translate(t, "driveDetails.title")} ${
                        driveDetails.driveNumber || ""
                    }`}
                </Typography>
            </Grid>

            <Grid item className="detailsTableItem" itemposition={1}>
                <DetailsTable
                    noDivider={itemDividers && !itemDividers.item_1}
                    rows={[
                        {
                            labelPath: "driveDetails.carrier",
                            value: driveDetails.carrier,
                            valueCellId: "lblDriveDetailsCarrier",
                        },
                        {
                            labelPath: "driveDetails.freightForwarder",
                            value: driveDetails.freightForwarder,
                            valueCellId: "lblDriveDetailsFreightForwarder",
                        },
                        {
                            labelPath: "driveDetails.dangerousGoods",
                            value: driveDetails.dangerousGoods
                                ? translate(t, "driveDetails.yes")
                                : translate(t, "driveDetails.no"),
                            valueCellId: "lblDriveDetailsDangerousGoods",
                        },
                    ]}
                />
            </Grid>
            <Grid item className="detailsTableItem" itemposition={2}>
                <DetailsTable
                    noDivider={itemDividers && !itemDividers.item_2}
                    rows={[
                        {
                            labelPath: "driveDetails.shipmentType",
                            value: driveDetails.shipmentType,
                            valueCellId: "lblDriveDetailsShipmentType"
                        },
                        {
                            labelPath: "driveDetails.shipmentNr",
                            value: driveDetails.shipmentNumber,
                            valueCellId: "lblDriveDetailsShipmentNr"
                        },
                        {
                            labelPath: "driveDetails.checkInInfo",
                        },
                    ]}
                />
            </Grid>
            <Grid item className="detailsTableItem" itemposition={3}>
                <DetailsTable
                    noDivider={itemDividers && !itemDividers.item_3}
                    rows={[
                        {
                            labelPath: "driveDetails.deliveryNr",
                            value: driveDetails.deliveryNumber,
                            valueCellId: "lblDriveDetailsDeliveryNr"
                        },
                        {
                            labelPath: "driveDetails.loadingBay",
                            value: driveDetails.loadingBay,
                            valueCellId: "lblDriveDetailsLoadingBay"
                        },
                    ]}
                />
            </Grid>
        </Grid>
    );
};

export const DriveDetailsProps = {
    carrier: PropTypes.string,
    checkInEnabled: PropTypes.bool,
    dangerousGoods: PropTypes.bool,
    deliveryNumber: PropTypes.string,
    driveNumber: PropTypes.string,
    freightForwarder: PropTypes.string,
    loadingBay: PropTypes.string,
    shipmentNumber: PropTypes.string,
    shipmentType: PropTypes.string,
};

DriveDetails.propTypes = {
    driveDetails: PropTypes.shape(DriveDetailsProps),
};

export default DriveDetails;
