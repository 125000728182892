import React from "react";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import { Grid, TextField, IconButton } from "@mui/material";
import translate from "flow-core-library-translator";
import { formInputCommonStyle } from "../../../../flow-core-components-shared/src/components/FormLayout/FormLayout";
import Autocomplete from "../../../../flow-core-components-shared/src/components/Autocomplete/Autocomplete";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";

import t from "../../translations.json";

/**
 * Driver section for DrivePreregistration form
 * @param {boolean} isCoDriver It marks the driver as codriver
 * @param {object} control React hook form control
 * @param {function} trigger Function for init validation manually on a field
 * @param {Array.object} countries Country dropdown data for the nationality field
 * @return {JSX.Element}
 */
const DriverSection = ({ isCoDriver, control, trigger, countries }) => {
    const inputNamePrefix = isCoDriver ? "codriver" : "driver";
    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
                <Controller
                    name={`${inputNamePrefix}FirstName`}
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                        <TextField
                            id={`in${inputNamePrefix}FirstName`}
                            required={!isCoDriver}
                            fullWidth
                            label={translate(t, "fields.firstName")}
                            sx={formInputCommonStyle}
                            inputProps={{ maxLength: 35 }}
                            error={!!error}
                            helperText={!!error && error.message}
                            {...field}
                        />
                    )}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <Controller
                    name={`${inputNamePrefix}LastName`}
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                        <TextField
                            id={`in${inputNamePrefix}LastName`}
                            required={!isCoDriver}
                            fullWidth
                            label={translate(t, "fields.lastName")}
                            sx={formInputCommonStyle}
                            inputProps={{ maxLength: 35 }}
                            error={!!error}
                            helperText={!!error && error.message}
                            {...field}
                        />
                    )}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <Controller
                    name={`${inputNamePrefix}Nationality`}
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                        <Autocomplete
                            id={`in${inputNamePrefix}Nationality`}
                            stringMode
                            error={!!error}
                            helperText={!!error && error.message}
                            label={translate(t, "fields.nationality")}
                            options={countries}
                            optionLabelPropName="name"
                            optionValuePropName="code"
                            sx={formInputCommonStyle}
                            inputProps={{ required: !isCoDriver }}
                            {...field}
                        />
                    )}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <Controller
                    name={`${inputNamePrefix}DateOfBirth`}
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                        <DatePicker
                            fullWidth
                            label={translate(t, "fields.birthday")}
                            sx={{ ...formInputCommonStyle, width: "100%" }}
                            slotProps={{
                                textField: {
                                    id: `in${inputNamePrefix}DateOfBirth`,
                                    error: !!error,
                                    helperText: !!error && error.message,
                                    required: !isCoDriver,
                                    ...field,
                                },
                            }}
                            onChange={field.onChange}
                            onClose={(value) => {
                                // Validate when a dialog was open but no value was selected
                                if (!value && !isCoDriver && trigger) {
                                    trigger("driverDateOfBirth");
                                }
                            }}
                            slots={{
                                openPickerButton: (params) => (
                                    <IconButton {...params}>
                                        <CalendarTodayIcon />
                                    </IconButton>
                                ),
                            }}
                        />
                    )}
                />
            </Grid>
            {!isCoDriver && (
                <Grid item xs={12}>
                    <Controller
                        name="driverMobileNumber"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                            <TextField
                                id={`in${inputNamePrefix}driverMobileNumber`}
                                error={!!error}
                                helperText={!!error && error.message}
                                fullWidth
                                label={translate(t, "fields.mobile")}
                                sx={formInputCommonStyle}
                                inputProps={{ maxLength: 20 }}
                                {...field}
                            />
                        )}
                    />
                </Grid>
            )}
        </Grid>
    );
};

DriverSection.propTypes = {
    isCoDriver: PropTypes.bool,
    control: PropTypes.object,
    trigger: PropTypes.func,
    countries: PropTypes.arrayOf(
        PropTypes.shape({
            code: PropTypes.string,
            name: PropTypes.string,
        })
    ),
};

export default DriverSection;
