import React from "react";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import { Grid, TextField } from "@mui/material";
import { formInputCommonStyle } from "../../../../flow-core-components-shared/src/components/FormLayout/FormLayout";
import translate from "flow-core-library-translator";

import t from "../../translations.json";

/**
 * FreightForwarder section for DrivePreregistration form
 * @param {object} control React hook form control
 * @return {JSX.Element}
 */
const FreightForwarderSection = ({ control }) => {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
                <Controller
                    name="freightForwarderName"
                    control={control}
                    render={({ field, fieldState: { error } }) => {
                        return (
                            <TextField
                                id="inFreightForwarder"
                                required
                                fullWidth
                                label={translate(t, "fields.freightForwarder")}
                                sx={formInputCommonStyle}
                                inputProps={{ maxLength: 50 }}
                                error={!!error}
                                helperText={!!error && error.message}
                                {...field}
                            />
                        );
                    }}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <Controller
                    name="freightForwarderSubsidiary"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                        <TextField
                            id="inFreightForwarderSubsidiary"
                            required
                            fullWidth
                            label={translate(
                                t,
                                "fields.freightForwarderLocation"
                            )}
                            sx={formInputCommonStyle}
                            inputProps={{ maxLength: 50 }}
                            error={!!error}
                            helperText={!!error && error.message}
                            {...field}
                        />
                    )}
                />
            </Grid>
        </Grid>
    );
};

FreightForwarderSection.propTypes = {
    control: PropTypes.object,
};

export default FreightForwarderSection;
