import axios from "axios";

const shouldMock = window.localStorage.getItem("mock");

if (shouldMock) console.log("[MockFactory] Mocking enabled."); // eslint-disable-line

/**
 * MockFactory
 */
class MockFactory {
    /**
     * get
     * @param   {array|string|object}  args
     * @return  {*}
     */
    get(...args) {
        return this.request("get", ...args);
    }

    /**
     * post
     * @param   {array|string|object}   args
     * @return  {*}
     */
    post(...args) {
        return this.request("post", ...args);
    }

    /**
     * post
     * @param   {array|string|object}   args
     * @return  {*}
     */
    put(...args) {
        return this.request("put", ...args);
    }

    /**
     * request
     * @param   {string}    method
     * @param   {array}     args
     * @return  {Promise}
     */
    request(method, ...args) {
        return axios[method](...args)
            .then((response) => Promise.resolve(response))
            .catch((error) => this.respond(error, args[0], method));
    }

    /**
     * respond
     * @param   {object}    error
     * @param   {string}    route
     * @param   {string}    method
     * @return  {*}
     */
    respond(error, route, method) {
        if (process.env.NODE_ENV === "development") {
            // we separate the isDevelopment and the shouldMock if statements to allow proper tree shaking by webpack
            if (shouldMock) {
                const getResponseData = require("./getResponseData").default;
                const response = { data: getResponseData(route, method) };
                return Promise.resolve(response);
            } else {
                return Promise.reject(error);
            }
        } else {
            return Promise.reject(error);
        }
    }
}

export default new MockFactory();
