import * as yup from "yup";
import translate from "flow-core-library-translator";
import moment from "moment";

import t from "../translations.json";

const phoneRegExp = /^(\+|\d)(\d|-|\s){9,}$/;
const requiredHelperText = translate(t, "formHelperTexts.required");
const maxAllowedCharsHelperText50 = translate(
    t,
    "formHelperTexts.maxAllowedChars",
    {
        maxAllowedChars: 50,
    }
);
const maxAllowedCharsHelperText35 = translate(
    t,
    "formHelperTexts.maxAllowedChars",
    {
        maxAllowedChars: 35,
    }
);

/**
 * Convert empty string value "" to null
 * @param {string} value
 * @param {string} originalValue
 * @return {string | null}
 */
const emptyStringToNull = (value, originalValue) =>
    typeof originalValue === "string" && originalValue === "" ? null : value;

const emptyFormValues = {
    freightForwarderName: "",
    freightForwarderSubsidiary: "",
    driverFirstName: "",
    driverLastName: "",
    driverNationality: "",
    driverDateOfBirth: null,
    driverMobileNumber: "",
    codriverFirstName: "",
    codriverLastName: "",
    codriverNationality: "",
    codriverDateOfBirth: null,
    truckLicensePlate: "",
    truckNationality: "",
    totalPermissibleWeightKg: "",
    estimatedTimeOfArrival: null,
};

/**
 * Compile default form values based on stored form data
 * @param {object} storedData
 * @return {object}
 */
export const getFormDefaultValues = (storedData) => {
    // If no stored data, return with empty form values
    if (!storedData) {
        return emptyFormValues;
    }

    // Merge empty form values and stored form data.
    // Convert date values
    const defaultValues = {};

    for (const [key, emptyValue] of Object.entries(emptyFormValues)) {
        const storedValue =
            storedData[key] &&
            [
                "driverDateOfBirth",
                "codriverDateOfBirth",
                "estimatedTimeOfArrival",
            ].includes(key)
                ? moment(storedData[key])
                : storedData[key];

        defaultValues[key] = storedValue || emptyValue;
    }

    return defaultValues;
};

export const formValidationSchema = yup.object().shape({
    freightForwarderName: yup
        .string()
        .max(50, maxAllowedCharsHelperText50)
        .required(requiredHelperText),
    freightForwarderSubsidiary: yup
        .string()
        .max(50, maxAllowedCharsHelperText50)
        .required(requiredHelperText),
    driverFirstName: yup
        .string()
        .max(35, maxAllowedCharsHelperText35)
        .required(requiredHelperText),
    driverLastName: yup
        .string()
        .max(35, maxAllowedCharsHelperText35)
        .required(requiredHelperText),
    driverNationality: yup.string().required(requiredHelperText),
    driverDateOfBirth: yup
        .date()
        .typeError(translate(t, "formHelperTexts.invalidFormat"))
        .required(requiredHelperText),
    driverMobileNumber: yup
        .string()
        .matches(
            phoneRegExp,
            translate(t, "formHelperTexts.invalidPhoneNumber")
        )
        .transform(emptyStringToNull)
        .nullable(),
    truckLicensePlate: yup.string().required(requiredHelperText),
    truckNationality: yup.string().required(requiredHelperText),
    totalPermissibleWeightKg: yup
        .number()
        .integer(translate(t, "formHelperTexts.invalidNumber"))
        .typeError(translate(t, "formHelperTexts.invalidNumber"))
        .min(2000, translate(t, "formHelperTexts.invalidWeight"))
        .max(44000, translate(t, "formHelperTexts.invalidWeight"))
        .transform(emptyStringToNull)
        .nullable(),
    estimatedTimeOfArrival: yup
        .date()
        .typeError(translate(t, "formHelperTexts.invalidFormat"))
        .required(requiredHelperText),
    codriverDateOfBirth: yup
        .date()
        .nullable()
        .typeError(translate(t, "formHelperTexts.invalidFormat")),
});
